import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import clip from "text-clipper";

// Component imports
import { deleteAuthor } from "../../../actions/SupplementsAction";
import { removeHTMLTag } from "../../../components/Utility/Variables";

// Images
import NoData from "../../../assets/images/no-data.gif";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { AuthorOperation } from "../../../enums/author";
import {
  ADD_CREDENTIALS,
  CANCEL,
  DELETE,
  DELETE_FROM_AUTHOR,
  EDIT,
  FROM_AUTHOR,
  NO_AUTHOR,
} from "../../../config/app_strings";
import ActionModal from "../../../components/Modal/ActionModal";
import { MoonLoader } from "react-spinners";

function ViewAuthor({ data, deleteAuthor }) {
  // state
  const [isModalOpen, setModalOpen] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [selectedDelletableFieldName, setSelectedDeletableFieldName] =
    useState("");
  const [selectedDeletableField, setSelectedDeletableField] = useState("");

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDeletableFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDeletableFieldName("");
  };

  // Mapping
  const mappingAuthor =
    data && data.length > 0 ? (
      data.map((data) => (
        <div
          className="flex flex-col sm:flex-row items-center w-full sm:w-1/2 lg:w-1/3 px-4 py-3 mb-4 sm:mb-0 space-y-2 sm:space-y-0 sm:space-x-6 rounded-lg shadow-lg bg-lightbg"
          key={data.data.author_id}
        >
          {data.links.image !== null && data.links.image !== "" ? (
            <img
              src={`${data.links.image}`}
              alt={data.data.attributes.name}
              className="avatar rounded-full object-cover"
            />
          
        
          ) : (
            <div className="avatar rounded-full bg-default flex items-center justify-center text-white">
            <p className="text-lg font-bold text-white">
              {data.data.attributes.name.charAt(0)}
            </p>
          </div>
          
          )}

          <div className="flex flex-col flex-wrap justify-between  text-textColor">
            <p className="w-44 text-lg font-bold truncate">
              {data.data.attributes.name}
            </p>

            <p className="w-44 mb-2 text-xs font-bold text-gray-300 truncate">
              {clip(removeHTMLTag(data.data.attributes.detail), 100, {
                html: true,
                maxLines: 8,
              })}
            </p>
            <div className="flex flex-row space-x-5">
              <Link
                to={{
                  pathname: "/author/create",
                }}
                state={{
                  selectedService: AuthorOperation.EDIT_AUTHOR,
                  selectedAuthor: data.data,
                }}
                className="text-sm text-gray-300 hover:text-lightDefault"
              >
                {EDIT}
              </Link>
              <button
                className="text-sm text-gray-300 hover:text-lightDefault"
                onClick={() => {
                  openingModalElement(
                    data.data.author_id,
                    data.data.attributes.name
                  );
                }}
              >
                {DELETE}
              </button>
            </div>
          </div>
        </div>
      ))
    ) : <MoonLoader size="50" color="#008fe7" />
    ;

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="flex flex-row flex-wrap w-full px-5 text-black placeholder-black bg-transparent">
      {mappingAuthor}
      <ActionModal
        fromName={FROM_AUTHOR}
        actionFromName={DELETE_FROM_AUTHOR}
        isModalOpen={isModalOpen}
        onClose={closingModalElement}
        onConfirm={async () => {
          await deleteAuthor(selectedDeletableField);
          closingModalElement();
        }}
        isProcessingDelete={isProcessingDelete}
        itemName={selectedDelletableFieldName}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAuthor: (authorId) => dispatch(deleteAuthor(authorId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAuthor);
