import axios from "axios";
import { toast } from "react-toastify";
import { LOGIN_AGAIN_TO_GAIN_ACCESS } from "../config/app_strings";

/// Api Definition
/* The code `export const instance = axios.create({ ... })` is creating a new instance of the Axios
library called `instance`. This instance is configured with a base URL of
"https://api.pharania.com/api/" and the `withCredentials` option set to `true`. This means that any
requests made using this instance will include credentials (such as cookies) when making
cross-origin requests. */
export const instance = axios.create({
  baseURL: "https://api.pharania.com/api/",
  // baseURL: "http://localhost:8000/api/",
  withCredentials: true,
});

/* The code `export const CSRFInstance = axios.create({ ... })` is creating a new instance of the Axios
library called `CSRFInstance`. This instance is configured with a base URL of
"https://api.pharania.com/sanctum/csrf-cookie". */
export const CSRFInstance = axios.create({
  baseURL: "https://api.pharania.com/sanctum/csrf-cookie",
  // baseURL: "http://localhost:8000/sanctum/csrf-cookie",

});

/* The code `export const apiWithOutCredentials = axios.create({ ... })` is creating a new instance of
the Axios library called `apiWithOutCredentials`. This instance is configured with a base URL of
"https://api.pharania.com" and the `withCredentials` option set to `true`. */
export const apiWithOutCredentials = axios.create({
   baseURL: "https://api.pharania.com",
  // baseURL: "http://localhost:8000",
  withCredentials: true,
});

/// Interceptor

/* The code `instance.interceptors.response.use(...)` is setting up an interceptor for the Axios
instance called `instance`. */
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    var baseUrl = error.config.baseURL;
    if (
      error.response.status === 401 &&
      error.request.responseURL !== `${baseUrl}login`
    ) {
      localStorage.clear();
      toast.warning(LOGIN_AGAIN_TO_GAIN_ACCESS);
      window.location = "/admin_root_login";
    }
  }
);
