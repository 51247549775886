import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

import HeroBackground from "../../../assets/images/HeroImage.jpg";
import {
  ALREADY_HAVE_ACCOUNT,
  CONTRACTED_COMPANY,
  CREATE_ACCOUNT,
  POWERED_BY,
  SIGN_IN,
} from "../../../config/app_strings";
import CreateReaderAccount from "../../../components/Form/CreateReaderAccount";
import { createReader } from "../../../actions/AuthAction";
import { connect } from "react-redux";

function CreateAccount(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundSize: "cover",
      }}
    >
      <Link
        to={"/"}
        className="absolute p-4 bg-white bg-opacity-25 rounded-full top-10 left-10"
      >
        <BiArrowBack size="26" color="#fff" />
      </Link>

      <div className="flex flex-col items-center justify-center px-5 py-16 shadow-2xl md:w-1/3 rounded-2xl">
        <p className="text-4xl font-extrabold text-lightbg">{CREATE_ACCOUNT}</p>

        <CreateReaderAccount
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          showConfirmPassword={showConfirmPassword}
          setShowConfirmPassword={setShowConfirmPassword}
          errorMessage={props.errorMessage}
          isreadersubmissionstatus={props.isReaderSubmissionSuccessful}
          isLoading={props.isLoading}
          createReader={props.createReader}
        />
        <div className="flex flex-row items-center justify-center w-full px-10 text-center">
          <Link to="/auth/sign-in" className="text-white">
            {ALREADY_HAVE_ACCOUNT} ?{" "}
            <span className="hover:text-lightDefault font-bold">{SIGN_IN}</span>
          </Link>
        </div>
      </div>

      <p className="mt-10 mb-10 text-bottom text-white">
        {POWERED_BY}
        <a href="https://vptrading.et" className="text-lightDefault font-bold">
          {` ${CONTRACTED_COMPANY}`}
        </a>
      </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.readerSubmissionError,
    isLoading: state.app.isGuestReaderloading,
    isReaderSubmissionSuccessful: state.auth.isReaderSubmissionSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createReader: (content) => dispatch(createReader(content)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
