import React, { Component } from "react";
import * as Yup from "yup";

import {
  CONFIRM_PASSWORD_FIELD,
  DATE_OF_BIRTH_AFTER,
  DATE_OF_BIRTH_BEFORE,
  DATE_OF_BIRTH_FIELD,
  DATE_OF_BIRTH_IS_REQUIRED,
  DATE_OF_BIRTH_MUST_VALID,
  EMAIL_FIELD,
  EMAIL_IS_REQUIRED,
  FIRST_NAME_CAN_ONLY_CONTAIN,
  FIRST_NAME_FIELD,
  FIRST_NAME_IS_REQUIRED,
  First_name_can_only_contain,
  LAST_NAME_CAN_ONLY_CONTAIN,
  LAST_NAME_FIELD,
  LAST_NAME_IS_REQUIRED,
  LAST_name_can_only_contain,
  PASSWORD_DOESNT_MATCH,
  PASSWORD_DONOT_MATCH,
  PASSWORD_FIELD,
  PASSWORD_IS_REQUIRED,
  PASSWORD_IS_TOO_LONG,
  SIGNIN_SUCCESSFUL,
  SIGNUP_FAILED,
  SIGNUP_SUCCESSFUL,
  SIGN_UP,
  SUBMIT,
  WRONG_EMAIL_FORMAT,
} from "../../config/app_strings";

import { ClipLoader } from "react-spinners";

import { Field, Form, Formik } from "formik";
import SnackbarMessage from "../SnackBarMessage";
import { SnackbarMessageType } from "../../enums/SnackBarMessageType";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

export default class CreateReaderAccount extends Component {
  render() {
    const {
      errorMessage,
      isLoading,
      createReader,
      isreadersubmissionstatus,
      showPassword,
      setShowPassword,
      showConfirmPassword,
      setShowConfirmPassword,
    } = this.props;

    const createAccountFormValidationSchema = Yup.object().shape({
      firstName: Yup.string()
        .required(FIRST_NAME_IS_REQUIRED)
        .matches(
          /^[a-zA-Z!@#$%^&*()_+=\[{\]};:<>|./?,-]+$/,
          FIRST_NAME_CAN_ONLY_CONTAIN
        ),
      lastName: Yup.string()
        .required(LAST_NAME_IS_REQUIRED)
        .matches(
          /^[a-zA-Z!@#$%^&*()_+=\[{\]};:<>|./?,-]+$/,
          LAST_NAME_CAN_ONLY_CONTAIN
        ),
      email: Yup.string().required(EMAIL_IS_REQUIRED).email(WRONG_EMAIL_FORMAT),
      dateOfBirth: Yup.date()
        .required(DATE_OF_BIRTH_IS_REQUIRED)
        .min(new Date("1900-01-01"), DATE_OF_BIRTH_AFTER)
        .max(new Date(), DATE_OF_BIRTH_BEFORE)
        .typeError(DATE_OF_BIRTH_MUST_VALID),
      password: Yup.string()
        .required(PASSWORD_IS_REQUIRED)
        .min(2, PASSWORD_IS_TOO_LONG)
        .max(15, PASSWORD_IS_TOO_LONG),
    });

    const submittingCreateAccount = async (values, resetForm) => {
      let content = {
        data: {
          type: "users",
          attributes: {
            name: values.firstName + " " + values.lastName,
            email: values.email,
            date_of_birth: values.dateOfBirth,
            password: values.password,
            role_id: 2,
          },
        },
      };

      await createReader(content);

      resetForm();
    };

    return (
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          dateOfBirth: "",
          password: "",
          confirmpassword: "",
        }}
        validationSchema={createAccountFormValidationSchema}
        onSubmit={(values, { resetForm }) =>
          submittingCreateAccount(values, resetForm)
        }
      >
        {({ errors, touched, values, resetForm }) => (
          <Form className="flex flex-col w-full px-5 pt-10 pb-5 space-y-4">
            <div className="flex flex-row space-x-5 w-full">
              <div className="flex flex-col justify-start space-x-3 w-full">
                <Field
                  name="firstName"
                  id="firstName"
                  type="text"
                  placeholder={FIRST_NAME_FIELD}
                  values
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full"
                />

                {errors.firstName && touched.firstName ? (
                  <small className="text-sm text-left text-lightbg">
                    {errors.firstName}
                  </small>
                ) : null}
              </div>
              <div className="flex flex-col justify-start space-x-3 w-full">
                <Field
                  name="lastName"
                  id="lastName"
                  type="text"
                  placeholder={LAST_NAME_FIELD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full"
                />

                {errors.lastName && touched.lastName ? (
                  <small className="text-sm text-left text-lightbg">
                    {errors.lastName}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="flex flex-row space-x-5 w-full">
              <div className="flex flex-col justify-start space-x-3 w-full">
                <Field
                  name="email"
                  id="email"
                  type="email"
                  placeholder={EMAIL_FIELD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50"
                />

                {errors.email && touched.email ? (
                  <small className="text-sm text-left text-lightbg">
                    {errors.email}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="flex flex-row space-x-5 w-full">
              <div className="flex flex-col justify-start space-x-3 w-full">
                <Field
                  name="dateOfBirth"
                  id="dateOfBirth"
                  type="date"
                  placeholder={DATE_OF_BIRTH_FIELD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full"
                />

                {errors.dateOfBirth && touched.dateOfBirth ? (
                  <small className="text-sm text-left text-lightbg">
                    {errors.dateOfBirth}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col justify-start space-y-3 w-full">
              <div className="relative">
                <Field
                  name="password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder={PASSWORD_FIELD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdVisibilityOff className="w-6 h-6" />
                  ) : (
                    <MdVisibility className="w-6 h-6" />
                  )}
                </button>
              </div>

              {errors.password && touched.password ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.password}
                </small>
              ) : null}
            </div>
            <div className="flex flex-col justify-start space-y-3 w-full">
              <div className="relative">
                <Field
                  name="confirmpassword"
                  id="confirmpassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={CONFIRM_PASSWORD_FIELD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <MdVisibilityOff className="w-6 h-6" />
                  ) : (
                    <MdVisibility className="w-6 h-6" />
                  )}
                </button>
              </div>

              {errors.confirmpassword && touched.confirmpassword ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.confirmpassword}
                </small>
              ) : null}

              {values.confirmpassword !== values.password && (
                <small className="text-red-500 text-sm">
                  {PASSWORD_DOESNT_MATCH}
                </small>
              )}
            </div>

            {/* Submit button */}

            {isLoading ? (
              <button
                className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
                disabled
              >
                <ClipLoader className="m-auto text-white" />
              </button>
            ) : (
              <button
                className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
                type="submit"
              >
                {SIGN_UP}
              </button>
            )}

            {isreadersubmissionstatus === true && (
              <SnackbarMessage
                type={SnackbarMessageType.SUCCESS}
                message={SIGNUP_SUCCESSFUL}
              />
            )}
            {isreadersubmissionstatus === false && (
              <SnackbarMessage
                type={SnackbarMessageType.ERROR}
                message={SIGNUP_FAILED}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  }
}
