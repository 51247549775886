/// This file includes all the necessary static
/// string files that are possessed in this web app

export const PHARANIA_COMIC_BOOK_ENGLISH = "Pharania Comic Book";
export const PHARANIA_COMIC_BOOK = "Bande dessinée Pharania";
export const PHARANIA = "Pharania";
export const POWERED_BY = "Powered By";
export const CONTRACTED_COMPANY = "VP Trading";
export const SIGN_IN = "Se connecter";
export const SIGN_UP = "S'inscrire";
export const ALREADY_HAVE_ACCOUNT = "Vous avez déjà un compte?";
export const CREATE_ACCOUNT = "Créer un compte";

export const SIGN_OUT = "se déconnecter";
export const LOADING_SIGN_OUT = " chargement";
export const PASSWORD_DOESNT_MATCH="Les mots de passe ne correspondent pas"

export const FIRST_NAME_IS_REQUIRED="Le prénom est requis";
export const FIRST_NAME_CAN_ONLY_CONTAIN="Le prénom ne peut contenir que des lettres et des symboles "


export const LAST_NAME_IS_REQUIRED="Le nom de famille est obligatoire";
export const LAST_NAME_CAN_ONLY_CONTAIN="Le nom de famille ne peut contenir que des lettres et des symboles"

export const EMAIL_IS_REQUIRED="L'e-mail est requis";
export const WRONG_EMAIL_FORMAT="Mauvais format d'e-mail";
export const DATE_OF_BIRTH_IS_REQUIRED="La date de naissance est obligatoire"
export const DATE_OF_BIRTH_AFTER="La date de naissance doit être postérieure au 1900-01-01"
export const DATE_OF_BIRTH_BEFORE="La date de naissance ne peut pas être postérieure"
export const DATE_OF_BIRTH_MUST_VALID="La date de naissance doit être une date valide"
export const FIRST_NAME_FIELD="Prénom"
export const LAST_NAME_FIELD="Nom de famille"
export const EMAIL_FIELD="E-mail"
export const DATE_OF_BIRTH_FIELD="Date de naissance"
export const PASSWORD_FIELD="Mot de passe"
export const CONFIRM_PASSWORD_FIELD="Confirmez le mot de passe"



export const PASSWORD_IS_REQUIRED="Mot de passe requis"
export const PASSWORD_IS_TOO_SHORT="Le mot de passe est trop court"
export const PASSWORD_IS_TOO_LONG="Password is too long"

// Genre related
export const GENRE_ADDED_SUCCESSFULLY = "Genre added successful";
export const GENRE_UPDATED_SUCCESSFULLY = "Genre updated successfully";
export const GENRE_DELETED_SUCCESSFULLY = "Genre deleted successfully";
export const GENRE_ADDED_FAILED = "Failed to add genre !";
export const GENRE_UPDATED_FAILED = "Failed to update genre !";
export const GENRE_DELETED_FAILED = "Failed to delete genre !";

// Tag related
export const TAG_ADDED_SUCCESSFULLY = "Tag added successfully";
export const TAG_UPDATED_SUCCESSFULLY = "Tag updated successfully";
export const TAG_DELETED_SUCCESSFULLY = "Tag deleted successfully";
export const TAG_ADDED_FAILED = "Failed to add tag !";
export const TAG_UPDATED_FAILED = "Failed to update tag !";
export const TAG_DELETED_FAILED = "Failed to delete tag !";

// Author related
export const AUTHOR_ADDED_SUCCESSFULLY = "Author added successfully";
export const AUTHOR_UPDATED_SUCCESSFULLY = "Author updated successfully";
export const AUTHOR_DELETED_SUCCESSFULLY = "Author deleted successfully";
export const AUTHOR_ADDED_FAILED = "Failed to add author !";
export const AUTHOR_UPDATED_FAILED = "Failed to update author !";
export const AUTHOR_DELETED_FAILED = "Failed to delete author !";
//author ranking
export const RESET_AUTHOR_RANKING="Reset author ranking"
export const AUTHOR_RANKING_RESET_SUCCESSFUL =
  "Resetting successful refresh page to view changes";
  export const AUTHOR_RANK_RESET_FAILED="Failed to reset author ranking"
  export const RESET_AUTHOR_RANKING_DESCRIPTION =
  "Reset author ranking list from your store";
  export const RESETIMG = "Reseting";
export const RESETING_AUTHOR_RANKING_DESCRIPTION="Author ranking list? This action will reset two month view of authors in store and can not be undone. "


// Series related
export const SERIES_ADDED_SUCCESSFULLY = "Series added successfully";
export const SERIES_UPDATED_SUCCESSFULLY = "Series updated successfully";
export const SERIES_DELETED_SUCCESSFULLY = "Series deleted successfully";
export const SERIES_ADDED_FAILED = "Failed to add series !";
export const SERIES_UPDATED_FAILED = "Failed to update series !";
export const SERIES_DELETED_FAILED = "Failed to delete series !";

// Comic book related
export const COMIC_BOOK_ADDED_SUCCESSFULLY = "Comic book added successfully";
export const COMIC_BOOK_UPDATED_SUCCESSFULLY =
  "Comic book updated successfully";
export const COMIC_BOOK_DELETED_SUCCESSFULLY =
  "Comic book deleted successfully";
export const COMIC_BOOK_ADDED_FAILED = "Failed to add comic book !";
export const COMIC_BOOK_UPDATED_FAILED = "Failed to update comic book !";
export const COMIC_BOOK_DELETED_FAILED = "Failed to delete comic book !";

// Advert related
export const ADVERT_ADDED_SUCCESSFULLY = "Advert added successfully";
export const ADVERT_UPDATED_SUCCESSFULLY = "Advert updated successfully";
export const ADVERT_DELETED_SUCCESSFULLY = "Advert deleted successfully";
export const ADVERT_ADDED_FAILED = "Failed to add advert !";
export const ADVERT_UPDATED_FAILED = "Failed to update advert !";
export const ADVERT_DELETED_FAILED = "Failed to delete advert !";

// User
export const USER_ADDED_SUCCESSFULLY = "User added successfully";
export const USER_UPDATED_SUCCESSFULLY = "User updated successfully";
export const USER_DELETED_SUCCESSFULLY = "User deleted successfully";
export const USER_ADDED_FAILED = "Failed to add user !";
export const USER_UPDATED_FAILED = "Failed to update user !";
export const USER_DELETED_FAILED = "Failed to delete user !";

// Page related
export const PAGE_ADDED_SUCCESSFULLY = "Page added successfully";
export const PAGE_UPDATED_SUCCESSFULLY = "Page updated successfully";
export const PAGE_DELETED_SUCCESSFULLY = "Page deleted successfully";
export const PAGE_ADDED_FAILED = "Failed to add page !";
export const PAGE_UPDATED_FAILED = "Failed to update page !";
export const PAGE_DELETED_FAILED = "Failed to delete page !";

// Chapter related
export const CHAPTER_ADDED_SUCCESSFULLY = "Chapter added successfully";
export const CHAPTER_DELETED_SUCCESSFULLY = "Chapter deleted successfully";
export const CHAPTER_ADDED_FAILED = "Failed to add chapter !";
export const CHAPTER_DELETED_FAILED = "failed to delete chapter !";

// Auth
export const LOGIN_SUCCESSFUL = "Login successful";
export const LOGIN_FAILED = "Login Failed ! ";
export const LOGOUT_SUCCESSFUL = "Logout successful";
export const LOGOUT_FAILED = "Logout Failed ! ";
export const LOGIN_AGAIN_TO_GAIN_ACCESS = "Login again to gain access.";
export const SIGNIN_SUCCESSFUL =
  "Your registration was successful. To fully utilize the platform, please sign in";

export const SIGNUP_SUCCESSFUL =
  "Votre inscription a réussi. Pour utiliser pleinement la plateforme, veuillez vous connecter";
export const SIGNUP_FAILED = "L'e-mail existe déjà, essayez-en un autre";
// Validation
export const MISSING_FIELD = "One or more fields must be provided.";
export const USER_NAME_REQUIRED = "Your username is required";
export const EMAIL_WRONG_FORMAT = "Email is in a wrong format";
export const PASSWORD_REQUIRED = "Your Password is required";
export const PASSWORD_TOO_SHORT = "Password is too short";
export const PASSWORD_TOO_LONG = "Password is too long";
export const INVALID_CHARACTER = "Invalid character is in use";
export const CHOOSE_OPTION_TO_DISPLAY_SECTION =
  "Choose the options above to display the respective section";
export const INVALID_EMAIL = "Invalid email format.";
export const PASSWORD_DONOT_MATCH = "Password don't match";
export const PUBLICATION_YEAR_WRONG_FORMAT =
  "Publication year is not in a proper format.";

// Share
export const SHARE_SUCCESSFUL = "Partagé avec succès !";
export const SHARE = "Partager";

//ActionModal related Advert
export const DELETING = "Deleting";
export const FROM_ADVERT = "from Advert";
export const DELETE_FROM_ADVERT =
  "from advert? All data will be permanently removed. This action cannot be undone.";

//ActionModal related Tag
export const FROM_TAG = "from Tag";
export const DELETE_FROM_TAG =
  "from tag? All data will be permanently removed. This action cannot be undone.";

//ActionModal related Genere
export const FROM_GENERE = "from Genres";
export const DELETE_FROM_GENERE =
  "from genre? All comic book data with this specific genre willbe permanently removed. This action cannot be undone.";

//ActionModal related Author
export const FROM_AUTHOR = " from Author";
export const DELETE_FROM_COMIC_BOOK =
  "  from comic book? All data will be permanently removed.This action cannot be undone.";

//ActionModal related Author
export const FROM_Comic = " from Comics";
export const DELETE_FROM_AUTHOR =
  " from author? All comic book data with this specific author will be permanently removed. This action cannot be undone.";

// Search related strings
export const SEARCH_FIELD_PLACEHOLDER = "Recherche";
export const SEARCH_RESULT = "Resultats";
export const SEARCH_RESULT_CHAPTER = "Autres chapiters de la meme serie";
export const SEARCH_RESULT_SUGGESTIONS = "Suggestions";

// Button
export const CLOSE = "Fermer";
export const EDIT = "Edit";
export const ADD_CREDENTIALS = "Add Credentials";
export const DELETE = "Delete😌";
export const RESET= "Reset😌";
export const CANCEL = "Cancel";
export const YES = "Yes";
export const NO = "No";
export const SUBMIT = "Submit";
export const LOGIN = "Login";
export const VIEW = "View";
export const ADD_ADVERT = "Add Advert";
export const EDIT_ADVERT = "Edit Advert";
export const ADD_AUTHOR = "Add Author";
export const ADD_AUTHOR_CREDENTIAL = "Add Author Credential";
export const EDIT_AUTHOR = "Edit Author";
export const ADD_SERIES = "Add Series";
export const EDIT_SERIES = "Edit Series";
export const ADD_COMIC = "Add Comic";
export const EDIT_COMIC = "Edit Comic";
export const ADD_GENRE = "Add Genre";
export const EDIT_GENRE = "Edit Genre";
export const ADD_TAG = "Add Tag";
export const EDIT_TAG = "Edit Tag";
export const ADD_ADVERT_TYPE = "Add Advert Type";
export const EDIT_ADVERT_TYPE = "Edit Advert Type";
export const EDIT_PROFILE = "Edit Profile";
export const EDIT_CHAPTER = "Edit Chapter";
export const EDIT_PAGE = "Edit Page";
export const CHANGE_PASSWORD = "Change Password";
export const FORGOT_PASSWORD = "Forgot Password";
export const LOGOUT = "Logout";

// Button Descriptions
export const ADD_ADVERT_DESCRIPTION = "Add new advert to your store";
export const TOTAL_ADVERT_IN_STORE = "Total Adverts in your Store";
export const TOTAL_AUTHOR_IN_STORE = "Total Authors in your Store";
export const TOTAL_COMIC_IN_STORE = "Total Comic Books in your Store";
export const TOP_COMIC_IN_STORE = "Top rated comic in your store ";
export const NUMBER_OF_VIEWS = "Number of views";
export const TOTAL_SERIES_IN_STORE = "Total Series in your Store";
export const TOTAL_GENRE_IN_STORE = "Total Genre in your Store";
export const TOTAL_ADMINS_IN_STORE = "Total admins in your Store";
export const TOTAL_USERS_IN_STORE = "Total users in your Store";
export const TOTAL_TAG_IN_STORE = "Total Tag in your Store";
export const ADD_NEW_ADVERT_DESCRIPTION = "Create and add new advert to store";
export const EDIT_ADVERT_DESCRIPTION = "Edit advert information on your store";
export const ADD_NEW_ADVERT_TYPE_DESCRIPTION =
  "Create and add new advert type to store";
export const EDIT_ADVERT_TYPE_DESCRIPTION =
  "Edit advert type information on your store";
  export const TOTAL_NUMBER_OF_VIEWS="Total Number Of Views";
  export const TOTAL_NUMBER_OF_TWO_MONTH_VIEWS="Total Number Of Two Month Views";
  
  
export const ADD_NEW_AUTHOR_DESCRIPTION = "Create and add new author to store";
export const EDIT_AUTHOR_DESCRIPTION = "Edit author information on your store";
export const ADD_AUTHOR_CREDENTIAL_DESCRIPTION = "Add credentials to your author";
export const ADD_NEW_COMIC_DESCRIPTION = "Create and add new comic to store";
export const EDIT_COMIC_DESCRIPTION = "Edit comic information on your store";
export const ADD_NEW_SERIES_DESCRIPTION = "Create and add new series to store";
export const EDIT_SERIES_DESCRIPTION = "Edit series information on your store";
export const ADD_NEW_GENRE_DESCRIPTION = "Create and add new genre to store";
export const EDIT_GENRE_DESCRIPTION = "Edit genre information on your store";
export const ADD_NEW_TAG_DESCRIPTION = "Create and add new tag to store";
export const EDIT_TAG_DESCRIPTION = "Edit tag information on your store";
export const TOTAL_COMIC_BOOKS = "Total Comic Books";
export const NUMBER_OF_SIGNED_USERS = "No of Signed Users";
export const NUMBER_OF_ADMIN_USERS = "No of Admin Staffs";
export const NUMBER_OF_GENRES = "No of Genres";
export const NUMBER_OF_AUTHORS = "No of Authors";
export const NUMBER_OF_LIVE_ADS = "No of Live Ads";
export const EDIT_PROFILE_DESCRIPTION =
  "Edit your profile information by navigating from here.";
export const CHANGE_PASSWORD_DESCRIPTION = "You can change your password here";
export const LOGOUT_DESCRIPTION = "Exit the system here.";
export const ADD_ADMIN_USER = "Add Admin User";
export const ADD_ADMIN_USER_DESCRIPTION =
  "Create and add admin user to the system";

export const GO_TO_HOME = "Aller à la maison";
export const LOADING = "Chargement";

export const POPULAR_COMIC = "Les plus populaires";
export const NEWLY_RELEASED = "Récemment publié";

// Menu Items
export const GENRE = "Genre";
export const AUTHOR = "Author";
export const PAGE = "Page";
export const TAG = "Tag";
export const CHAPTERS = "Chapitres";
export const SUGGESTIONS = "Suggestions";
export const ADVERT = "Advert";
export const COMIC_BOOK = "Comic Books";
export const SERIES = "Series";
export const SUPPLEMENTS = "Supplements";
export const ADVERTISEMENT = "Advertisement";
export const AUTHOR_RANKING = "Author Ranking";
export const AVERAGE_RATING = "Average rating";
// No Data
export const NO_PAGE_IN_CHAPTER = "Aucune page trouvée dans ce chapitre";
export const NO_PAGE_FOUND = "Aucune page trouvée";
export const NO_RECOMMENDATION = "Aucune recommandation trouvée";
export const NO_DATA = "Aucune donnée disponible !";
export const NO_AUTHOR = "No Author in the Store";
export const NO_CHAPTER = "No Chapter in the Store";
export const NO_PAGE = "No Page in the Store";
export const NO_LIVE_AD = "No Live Ad";
export const NO_GENRE = "No Genre in the Store";
export const NO_TAG = "No Tag in the Store";
export const NO_ROLE = "No Role in the Store";
export const NO_SERIES = "No Series in the Store";
export const NO_COMIC = "No Comic in the Store";

// Column Headers and input field label

export const IMAGE = "Image";
export const ADVERT_TYPE = "Advert Type";
export const COMPANY_NAME = "Company Name";
export const DESCRIPTION = "Description";
export const CURRENTLY_LIVE = "Currently Live";
export const ACTION = "Action";
export const ADVERT_IMAGE = "Advert Image";
export const EMAIL = "Email";
export const PASSWORD = "Password";
export const AUTHOR_NAME = "Author Name";
export const AUTHOR_DESCRIPTION = "Author Description";
export const PROFILE_IMAGE = "Profile Image";
export const TITLE = "Title";
export const PUBLICATION_YEAR = "Publication Year";
export const VIEW_COUNT = "View Count";
export const RATING = "Rating";
export const IS_FEATURED = "Is Featured";
export const FULL_NAME = "Full Name";
export const CURRENT_PASSWORD = "Current Password";
export const NEW_PASSWORD = "New Password";
export const CONFIRM_PASSWORD = "Confirm Password";
export const GENRE_NAME = "Genre Name";
export const TAG_NAME = "Tag Name";
export const ROLE = "Role";
export const CHAPTER_TITLE = "Chapter Title";
export const PAGE_TITLE = "Page Title";
export const VIEWS = "Number of views";
export const TWO_MONTH_VIEWS = "Two Month Views";
export const DATE_OF_BIRTH="Date Of Birth";
export const CREATED_AT="Created At";


// Options
export const DO_YOU_WANT_IT_TO_BE_LIVE = "Do you want it to be live ?";
export const CHOOSE_OPTION = "Choose Option";
export const REGISTER_COMIC = "Register Comic";
export const REGISTER_PAGE_IN_CHAPTER = "Register Page in a Chapter";
export const REGISTER_CHAPTER_IN_COMIC = "Register Chapter in a Comic Book";
export const ADMINS = "Admins";
export const USERS = "Users";
export const DOES_BELONGS_TO_SERIES =
  "Does this comic book belongs to a series ?";

// Headers
export const ADVERT_MANAGEMENT = "Advert Management";
export const ADVERT_TYPE_MANAGEMENT = "Advert Type Management";
export const SUMMARY = "Summary";
export const WELCOME = "Welcome";
export const AUTHOR_MANAGEMENT = "Author Management";
export const PERFORM_UTILITY_FUNCTIONS_ON_AUTHORRanking =
  "Perform utility functions on author ranking";
export const PERFORM_UTILITY_FUNCTIONS_ON_AUTHOR =
  "Perform utility functions on author";
export const PERFORM_UTILITY_FUNCTIONS_ON_COMIC =
  "Perform utility functions on comic books";
export const PERFORM_UTILITY_FUNCTIONS_ON_SUPPLEMENTS =
  "Perform utility functions on supplements";
export const PERFORM_UTILITY_FUNCTIONS_ON_USERS =
  "Perform utility functions on users";
export const UTILITY = "Utility";
export const COMIC_MANAGEMENT = "Comic Management";
export const SERIES_MANAGEMENT = "Series Management";
export const DASHBOARD = "Dashboard";
export const WELCOME_ADMIN = WELCOME + " Admin";
export const QUICK_STAT = "Quick Stats";
export const LIVE_ADVERTISEMENT = "Live advertisement";
export const HELLO = "Hello";
export const SETTINGS = "Settings";
export const GENRE_MANAGEMENT = "Genre Management";
export const TAG_MANAGEMENT = "Tag Management";
export const ADMIN_USER_MANAGEMENT = "Admin User Management";
export const USER_MANAGEMENT = "User Management";
export const AUTHOR_RANKINGS = "Author Ranking";

// Header description
export const ADVERT_MANAGEMENT_DESCRIPTION =
  "Manage lists of advert in your store";
export const ADVERT_TYPE_MANAGEMENT_DESCRIPTION =
  "Manage lists of advert type in your store";
export const AUTHOR_MANAGEMENT_DESCRIPTION = "Manage author in your store";
export const COMIC_MANAGEMENT_DESCRIPTION =
  "Manage list of comics in your store";
export const SERIES_MANAGEMENT_DESCRIPTION =
  "Manage list of series in your store";
export const GENRE_MANAGEMENT_DESCRIPTION =
  "Manage list of genres in your store";
export const TAG_MANAGEMENT_DESCRIPTION = "Manage list of tags in your store";
export const ADMIN_MANAGEMENT_DESCRIPTION =
  "Manage list of admin in your store";
export const USER_MANAGEMENT_DESCRIPTION = "Manage list of users in your store";
export const AUTHOR_RANKING_DESCRIPTION = "Manage author ranks in your store";

// Static select options
export const ADVERT_TYPE_LANDSCAPE = "Landscape";
export const ADVERT_TYPE_PORTRAIT = "Portrait";
export const ADVERT_TYPE_SQUARE = "Square";

// Notice
export const NOTE = "Note";
export const ADD_CHAPTER_NOTICE =
  "Please add the chapters in a ascending order starting from the first section of the book.";
export const ADD_PAGE_NOTICE =
  "Please add the pages by making the page number as a file name for the respective page image file. For example, 1.png or 1.jpeg";
