import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import { ClipLoader } from "react-spinners";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { guestLogin } from "../../actions/AuthAction";
import { resetLoader } from "../../actions/AppAction";
import SnackbarMessage from "../SnackBarMessage";
import { SnackbarMessageType } from "../../enums/SnackBarMessageType";
import {
  EMAIL_FIELD,
  EMAIL_IS_REQUIRED,
  PASSWORD_FIELD,
  PASSWORD_IS_REQUIRED,
  PASSWORD_IS_TOO_LONG,
  PASSWORD_IS_TOO_SHORT,
  WRONG_EMAIL_FORMAT,
} from "../../config/app_strings";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

class SignInAccount extends Component {
  render() {
    const { showPassword, setShowPassword } = this.props;
    const { isLoading, guestLogin, isLoggedIn } = this.props;

    // Yup validation schema
    const SignInFormValidationSchema = Yup.object().shape({
      email: Yup.string().required(EMAIL_IS_REQUIRED).email(WRONG_EMAIL_FORMAT),
      password: Yup.string()
        .required(PASSWORD_IS_REQUIRED)
        .min(2, PASSWORD_IS_TOO_SHORT)
        .max(15, PASSWORD_IS_TOO_LONG),
    });

    const submittingSignIn = async (values) => {
      guestLogin(values.email, values.password);
    };

    if (isLoggedIn) {
      if (localStorage.getItem("login_role_id") === "2")
        return <Navigate to="/" />;
    }

    return (
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          dateOfBirth: "",
          password: "",
        }}
        validationSchema={SignInFormValidationSchema}
        onSubmit={(values) => submittingSignIn(values)}
      >
        {({ errors, touched, values, resetForm }) => (
          <Form className="flex flex-col w-full px-5 pt-10 pb-5 space-y-4">
            <div className="flex flex-col justify-start space-y-3 w-full">
              <Field
                name="email"
                id="email"
                type="email"
                placeholder={EMAIL_FIELD}
                className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50"
              />
              {errors.email && touched.email ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.email}
                </small>
              ) : null}
            </div>
            <div className="flex flex-col justify-start space-y-3 w-full">
              <div className="relative">
                <Field
                  name="password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder={PASSWORD_FIELD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdVisibilityOff className="w-6 h-6" />
                  ) : (
                    <MdVisibility className="w-6 h-6" />
                  )}
                </button>
              </div>

              {errors.password && touched.password ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.password}
                </small>
              ) : null}
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="py-2 bg-white border rounded-lg text-default hover:bg-lightDefault hover:border hover:border-white hover:text-white"
            >
              {isLoading ? (
                <ClipLoader
                  height="35"
                  width="4"
                  radius="2"
                  margin="2"
                  color="#fff"
                />
              ) : (
                "Se connecter"
              )}
            </button>
            {/* {isLoggedIn === true && (
              <SnackbarMessage
                type={SnackbarMessageType.SUCCESS}
                message="You are successfully logged in"
              />
            )} */}
            {isLoggedIn === false && (
              <SnackbarMessage
                type={SnackbarMessageType.ERROR}
                message="Mot de passe incorrect, réessayez"
              />
            )}

            <div className="flex flex-row items-center justify-center w-full px-10 text-center">
              <Link to="/auth/create" className="text-white">
                Nouveau sur Pharania ?{" "}
                <span className="hover:text-lightDefault font-bold">
                  S'inscrire
                </span>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginFailedError: state.auth.loginFailedError,
    isLoggedIn: state.auth.isLoggedIn,
    isLoading: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    guestLogin: (email, password) => dispatch(guestLogin(email, password)),
    resetLoader: (type) => dispatch(resetLoader(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInAccount);
