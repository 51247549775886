import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { AiTwotoneSetting } from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";
import { FiUnlock, FiLogOut } from "react-icons/fi";

// Component import
import SideNav from "../../../components/Nav/SideNav";
import { instance, apiWithOutCredentials } from "../../../config/api";
import { numberFormat } from "../../../components/Utility/Variables";
import {
  encryptAndDecryptLocalStorage,
  isUserStillLoggedIn,
} from "../../../actions/AuthAction";
import { toast } from "react-toastify";
import { UserOperation } from "../../../enums/user";
import {
  ADVERT_TYPE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_DESCRIPTION,
  DASHBOARD,
  EDIT_PROFILE,
  EDIT_PROFILE_DESCRIPTION,
  HELLO,
  IS_FEATURED,
  LIVE_ADVERTISEMENT,
  LOGOUT,
  LOGOUT_DESCRIPTION,
  NO_LIVE_AD,
  NUMBER_OF_ADMIN_USERS,
  NUMBER_OF_AUTHORS,
  NUMBER_OF_GENRES,
  NUMBER_OF_LIVE_ADS,
  NUMBER_OF_SIGNED_USERS,
  PHARANIA,
  QUICK_STAT,
  SETTINGS,
  TOTAL_COMIC_BOOKS,
  VIEW,
  WELCOME_ADMIN,
} from "../../../config/app_strings";
import ShimmerIndicator from "../../../components/Shimmer/ShimmerIndicator";

function Dashboard() {
  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="flex-col md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />
      <DashboardBody />
    </div>
  );
}

const DashboardBody = ({ isLoading }) => {
  return (
    <div className="flex flex-row w-full bg-lightbg">
      <DashboardLeftSide />
      <DashboardRightSide />
    </div>
  );
};

const DashboardLeftSide = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalComicBooks, setTotalComicBooks] = useState(0);
  const [totalGuestUsers, setTotalGuestUsers] = useState(0);
  const [totalAdminStaff, setTotalAdminStaff] = useState(0);
  const [totalGenre, setTotalGenre] = useState(0);
  const [totalAuthor, setTotalAuthor] = useState(0);
  const [totalLiveAd, setTotalLiveAd] = useState(0);

  useEffect(() => {
    async function fetchDashboardStats() {
      setIsLoading(true);
      await instance
        .get("dashboard-stats", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((response) => {
         
          setTotalComicBooks(response.data.data.attributes.total_comic_books);
            setTotalGuestUsers(
              response.data.data.attributes.total_no_of_signed_users
            );
            setTotalAdminStaff(
              response.data.data.attributes.total_no_of_admin_users
            );
            setTotalGenre(response.data.data.attributes.total_no_of_genres);
            setTotalAuthor(response.data.data.attributes.total_no_of_authors);
            setTotalLiveAd(response.data.data.attributes.total_no_of_live_ads);
        })
        .catch((error) => {});
      setIsLoading(false);
    }

    fetchDashboardStats();
  }, []);

  const quickStats = [
    {
      id: 1,
      quickStatType: TOTAL_COMIC_BOOKS,
      statNumber: totalComicBooks,
      viewLink: "/comics",
    },
    {
      id: 2,
      quickStatType: NUMBER_OF_SIGNED_USERS,
      statNumber: totalGuestUsers,
      viewLink: "/users",
    },
    {
      id: 3,
      quickStatType: NUMBER_OF_ADMIN_USERS,
      statNumber: totalAdminStaff,
      viewLink: "/users",
    },
    {
      id: 4,
      quickStatType: NUMBER_OF_GENRES,
      statNumber: totalGenre,
      viewLink: "/supplements",
    },
    {
      id: 5,
      quickStatType: NUMBER_OF_AUTHORS,
      statNumber: totalAuthor,
      viewLink: "/author",
    },
    {
      id: 6,
      quickStatType: NUMBER_OF_LIVE_ADS,
      statNumber: totalLiveAd,
      viewLink: "/advert",
    },
  ];

  const quickStatMapping =
    quickStats.length > 0 ? (
      quickStats.map((SingleStat) => {
        return (
          <div
            className="flex flex-col items-center w-1/4 px-5 py-8 bg-white shadow-xl rounded-2xl"
            key={SingleStat.id}
          >
            <p className="text-sm font-thin">{SingleStat.quickStatType}</p>
            <p className="mt-5 text-4xl font-bold text-textColor">
              {numberFormat.format(SingleStat.statNumber)}
            </p>
            <Link
              to={SingleStat.viewLink}
              className="flex flex-row items-center mt-4 text-passOverColor"
            >
              {VIEW}
              <span className="ml-3">
                <BsArrowRight size="25" />
              </span>
            </Link>
          </div>
        );
      })
    ) : (
      <p className="text-center">No Stat</p>
    );

  const recentOrderMapping =
    totalLiveAd.length > 0 ? (
      totalLiveAd.map((SingleOrder, index) => {
        return index < 3 ? (
          <div
            className="w-1/3 px-5 py-8 mt-8 bg-white shadow-xl rounded-2xl"
            key={SingleOrder.data.advertisement_id}
          >
            <p className="text-sm font-bold text-textColor">
              {SingleOrder.data.attributes.company_name}
            </p>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col mt-5 space-y-1">
                <p className="text-sm font-bold text-gray-300">{ADVERT_TYPE}</p>
                <p className="text-sm font-light capitalize text-textColor">
                  {SingleOrder.data.attributes.type}
                </p>
              </div>
              <div className="flex flex-col mt-5 space-y-1">
                <p className="text-sm font-bold text-right text-gray-300">
                  {IS_FEATURED}
                </p>
                <p className="text-sm font-light text-right text-textColor">
                  {SingleOrder.data.attributes.is_featured ? "Yes" : "No"}
                </p>
              </div>
            </div>
            <hr className="mt-5 border border-gray-100" />
            <Link
              to="/advert"
              className="flex flex-row items-center mt-4 text-passOverColor"
            >
              {VIEW}
              <span className="ml-3">
                <BsArrowRight size="25" />
              </span>
            </Link>
          </div>
        ) : null;
      })
    ) : (
      <p className="m-auto mt-20 font-light text-center">{NO_LIVE_AD}</p>
    );

  return (
    <div className="w-3/4 px-10 text-left">
      {/* Dashboard Header */}
      <div className="flex flex-col py-10">
        <p className="text-4xl font-bold text-textColor">{DASHBOARD}</p>
        <p className="text-sm font-thin">{WELCOME_ADMIN}</p>
      </div>
      {/* Quick Stats */}
      <div className="flex flex-col">
        <p className="font-bold text-textColor">{QUICK_STAT}</p>
        <div className="flex flex-row flex-wrap mt-8">
          {isLoading ? (
            <ShimmerIndicator width={250} height={170} count={6} />
          ) : (
            quickStatMapping
          )}
        </div>
      </div>
      {/* Recent Orders */}
      <div className="flex flex-col mt-10">
        <p className="font-bold text-textColor">{LIVE_ADVERTISEMENT}</p>
        <div className="flex flex-row flex-wrap mb-5 space-x-0">
          {recentOrderMapping}
        </div>
      </div>
    </div>
  );
};

const DashboardRightSide = () => {
  let history = useNavigate();

  const onLogOutButtonClicked = async () => {
    const getAccessToken = await encryptAndDecryptLocalStorage(
      localStorage.getItem("login_user_token"),
      false
    );
    const header = {
      headers: {
        Authorization: `Bearer ${getAccessToken}`,
      },
    };

    try {
      await apiWithOutCredentials
        .get("/sanctum/csrf-cookie")
        .then(() => {
          apiWithOutCredentials
            .post("api/logout", {}, header)
            .then(() => {
              localStorage.clear();
              history("/admin_root_login");
            })
            .catch((error) => toast.error(error));
        })
        .catch((error) => toast.error(error));
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="w-1/3 min-h-screen px-10 overflow-y-auto text-left bg-white rounded-l-2xl">
      {/* User Profile Information */}
      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-col w-1/2 py-10">
          <p className="text-lg font-thin">{HELLO},</p>
          <p className="text-4xl font-bold text-textColor">{PHARANIA}!</p>
        </div>
        <div className="w-1/2"></div>
      </div>

      {/* Settings */}
      <div className="mt-8">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-bold text-textColor">{SETTINGS}</p>
          <AiTwotoneSetting className="text-default" size="25" />
        </div>
        {/* Edit Profile */}
        <Link
          to={{ pathname: "/edit" }}
          state={{ selectedService: UserOperation.EDIT_PROFILE }}
          className="flex flex-row items-start mt-8 space-x-5"
        >
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiEditBoxLine size="30" className="text-lightbg" />
          </div>
          <div className="text-textColor">
            <p className="font-bold">{EDIT_PROFILE}</p>
            <p className="text-sm text-gray-300">{EDIT_PROFILE_DESCRIPTION}</p>
          </div>
        </Link>
        {/* Change Password */}
        <Link
          to={{
            pathname: "/edit",
          }}
          state={{ selectedService: UserOperation.CHANGE_PASSWORD }}
          className="flex flex-row items-start mt-8 space-x-5"
        >
          <div className="p-3 bg-passOverColor rounded-xl">
            <FiUnlock size="30" className="text-lightbg" />
          </div>
          <div className="text-textColor">
            <p className="font-bold">{CHANGE_PASSWORD}</p>
            <p className="text-sm text-gray-300">
              {CHANGE_PASSWORD_DESCRIPTION}
            </p>
          </div>
        </Link>
        {/* Logout */}
        <div
          onClick={() => onLogOutButtonClicked()}
          className="flex flex-row items-start mt-8 space-x-5 cursor-pointer"
        >
          <div className="p-3 bg-passOverColor rounded-xl">
            <FiLogOut size="30" className="text-lightbg" />
          </div>
          <div className="text-textColor">
            <p className="font-bold">{LOGOUT}</p>
            <p className="text-sm text-gray-300">{LOGOUT_DESCRIPTION}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
