import {
  ADD_AUTHOR_FAILED,
  ADD_AUTHOR_SUCCESS,
  ADD_GENRE_FAILED,
  ADD_GENRE_SUCCESS,
  ADD_TAG_FAILED,
  ADD_TAG_SUCCESS,
  AUTHOR_RANKING_RESET_FAILED,
  AUTHOR_RANKING_RESET_SUCCESS,
  AUTHOR_RANK_RESET_START_LOADER,
  AUTHOR_RANK_RESET_STOP_LOADER,
  AUTHOR_START_LOADER,
  AUTHOR_STOP_LOADER,
  DELETE_AUTHOR_FAILED,
  DELETE_AUTHOR_SUCCESS,
  DELETE_GENRE_FAILED,
  DELETE_GENRE_SUCCESS,
  DELETE_TAG_FAILED,
  DELETE_TAG_SUCCESS,
  EDIT_AUTHOR_FAILED,
  EDIT_AUTHOR_SUCCESS,
  EDIT_GENRE_FAILED,
  EDIT_GENRE_SUCCESS,
  EDIT_TAG_FAILED,
  EDIT_TAG_SUCCESS,
  GENRE_START_LOADER,
  GENRE_STOP_LOADER,
  TAG_START_LOADER,
  TAG_STOP_LOADER,
} from "./Type";
import { instance, CSRFInstance } from "../config/api";
import { startLoader, stopLoader } from "./AppAction";
import { encryptAndDecryptLocalStorage } from "./AuthAction";

// Genre
export const registerGenre = (genreName) => {
  return (dispatch) => {
    // Input
    const content = {
      data: {
        attributes: {
          name: genreName,
        },
      },
    };

    dispatch(startLoader(GENRE_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/genres", content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            dispatch({ type: ADD_GENRE_SUCCESS });
            dispatch(stopLoader(GENRE_STOP_LOADER));
          } else {
            dispatch({ type: ADD_GENRE_FAILED, payload: res.data.message });
            dispatch(stopLoader(GENRE_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({ type: ADD_GENRE_FAILED, payload: err.response.data.message });
          dispatch(stopLoader(GENRE_STOP_LOADER));
        });
    });
  };
};

export const updateGenre = (genreName, genreId) => {
  return (dispatch) => {
    // Input
    const content = {
      data: {
        attributes: {
          name: genreName,
        },
      },
    };

    dispatch(startLoader(GENRE_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .patch("/genres/" + genreId, content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: EDIT_GENRE_SUCCESS });
            dispatch(stopLoader(GENRE_STOP_LOADER));
          } else {
            dispatch({ type: EDIT_GENRE_FAILED, payload: res.data.message });
            dispatch(stopLoader(GENRE_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({ type: EDIT_GENRE_FAILED, payload: err.response.data.message });
          dispatch(stopLoader(GENRE_STOP_LOADER));
        });
    });
  };
};

export const deleteGenre = (genreId) => {
  return (dispatch) => {
    // loader function
    dispatch(startLoader(GENRE_START_LOADER));

    // operation
    CSRFInstance.get("")
      .then(() =>
        instance
          .delete("genres/" + genreId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
            },
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch(stopLoader(GENRE_STOP_LOADER));
              dispatch({ type: DELETE_GENRE_SUCCESS });
            } else {
              dispatch(stopLoader(GENRE_STOP_LOADER));
              dispatch({
                type: DELETE_GENRE_FAILED,
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(GENRE_STOP_LOADER));
            dispatch({
              type: DELETE_GENRE_FAILED,
              payload: error.response.data.message,
            });
          })
      )
      .catch((error) => {
        dispatch(stopLoader(GENRE_STOP_LOADER));
        dispatch({
          type: DELETE_GENRE_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};

// Tags
export const registerTag = (tagName) => {
  return (dispatch) => {
    // Input
    const content = {
      data: {
        attributes: {
          name: tagName,
        },
      },
    };

    dispatch(startLoader(TAG_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/tags", content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            dispatch({ type: ADD_TAG_SUCCESS });
            dispatch(stopLoader(TAG_STOP_LOADER));
          } else {
            dispatch({ type: ADD_TAG_FAILED, payload: res.data.message });
            dispatch(stopLoader(TAG_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({ type: ADD_TAG_FAILED, payload: err.response.data.message });
          dispatch(stopLoader(TAG_STOP_LOADER));
        });
    });
  };
};

export const updateTag = (tagName, tagId) => {
  return (dispatch) => {
    // Input
    const content = {
      data: {
        attributes: {
          name: tagName,
        },
      },
    };

    dispatch(startLoader(TAG_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .patch("/tags/" + tagId, content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: EDIT_TAG_SUCCESS });
            dispatch(stopLoader(TAG_STOP_LOADER));
          } else {
            dispatch({ type: EDIT_TAG_FAILED, payload: res.data.message });
            dispatch(stopLoader(TAG_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({ type: EDIT_TAG_FAILED, payload: err.response.data.message });
          dispatch(stopLoader(TAG_STOP_LOADER));
        });
    });
  };
};

export const deleteTag = (tagId) => {
  return (dispatch) => {
    // loader function
    dispatch(startLoader(TAG_START_LOADER));

    // operation
    CSRFInstance.get("")
      .then(() =>
        instance
          .delete("tags/" + tagId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
            },
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch(stopLoader(TAG_STOP_LOADER));
              dispatch({ type: DELETE_TAG_SUCCESS });
            } else {
              dispatch(stopLoader(TAG_STOP_LOADER));
              dispatch({
                type: DELETE_TAG_FAILED,
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(TAG_STOP_LOADER));
            dispatch({
              type: DELETE_TAG_FAILED,
              payload: error.response.data.message,
            });
          })
      )
      .catch((error) => {
        dispatch(stopLoader(TAG_STOP_LOADER));
        dispatch({
          type: DELETE_TAG_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};

// Author
export const registerAuthor = (authorFormDataContent) => {
  return (dispatch) => {
    dispatch(startLoader(AUTHOR_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/authors", authorFormDataContent, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            dispatch({ type: ADD_AUTHOR_SUCCESS });
            dispatch(stopLoader(AUTHOR_STOP_LOADER));
          } else {
            console.log(res.errors);
            dispatch({ type: ADD_AUTHOR_FAILED, payload: Object.values(res.errors)[0]});
            dispatch(stopLoader(AUTHOR_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({ type: ADD_AUTHOR_FAILED, payload: err, });
          dispatch(stopLoader(AUTHOR_STOP_LOADER));
        });
    });
  };
};

export const updateAuthor = (authorFormDataContent, authorId) => {
  return (dispatch) => {
    dispatch(startLoader(AUTHOR_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/authors/" + authorId, authorFormDataContent, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: EDIT_AUTHOR_SUCCESS });
            dispatch(stopLoader(AUTHOR_STOP_LOADER));
          } else {
            dispatch({ type: EDIT_AUTHOR_FAILED, payload: res.data.message });
            dispatch(stopLoader(AUTHOR_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({ type: EDIT_AUTHOR_FAILED, payload: err.response.data.message });
          dispatch(stopLoader(AUTHOR_STOP_LOADER));
        });
    });
  };
};

export const deleteAuthor = (authorId) => {
  return (dispatch) => {
    // loader function
    dispatch(startLoader(AUTHOR_START_LOADER));

    // operation
    CSRFInstance.get("")
      .then(() =>
        instance
          .delete("authors/" + authorId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(localStorage.getItem("login_user_token"), false)}`,
            },
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch(stopLoader(AUTHOR_STOP_LOADER));
              dispatch({ type: DELETE_AUTHOR_SUCCESS });
            } else {
              dispatch(stopLoader(AUTHOR_STOP_LOADER));
              dispatch({
                type: DELETE_AUTHOR_FAILED,
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(AUTHOR_STOP_LOADER));
            dispatch({
              type: DELETE_AUTHOR_FAILED,
              payload: error.response.data.message,
            });
          })
      )
      .catch((error) => {
        dispatch(stopLoader(AUTHOR_STOP_LOADER));
        dispatch({
          type: DELETE_AUTHOR_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};




export const resetViews = () => {
  return (dispatch) => {
    dispatch(startLoader(AUTHOR_RANK_RESET_START_LOADER));

    CSRFInstance.get("").then(() => {
      instance
        .post(
          "/reset-views",
          {},
          {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token"),
                false
              )}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 204) {
            dispatch(stopLoader(AUTHOR_RANK_RESET_STOP_LOADER));
            dispatch({ type: AUTHOR_RANKING_RESET_SUCCESS});
          } else {
            dispatch(stopLoader(AUTHOR_RANK_RESET_STOP_LOADER));
            dispatch({
              type: AUTHOR_RANKING_RESET_FAILED,
              payload: res.data,
            });
          }
        })
        .catch((err) => {
          dispatch(stopLoader(AUTHOR_RANK_RESET_STOP_LOADER));
          dispatch({
            type: AUTHOR_RANKING_RESET_FAILED,
            payload: err,
          });
        });
    });
  };
}