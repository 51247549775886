import React from "react";
import { SnackbarMessageType } from "../enums/SnackBarMessageType";
import { FaCircleCheck } from "react-icons/fa6";
import { FaExclamationCircle } from "react-icons/fa";


const SnackbarMessage = (props) => {
  const { type, message } = props;

  const getIcon = () => {
    switch (type) {
      case SnackbarMessageType.SUCCESS:
        return <FaCircleCheck size="50" className="text-successicon" />;
      case SnackbarMessageType.ERROR:
        return <FaExclamationCircle size="50" className="text-red-400" />
        
      default:
        return null; // No icon for the default case
    }
  };

  const getBackgroundColor = () => {
    switch (type) {
      case SnackbarMessageType.SUCCESS:
        return 'bg-succesfullysigned'; 
      case SnackbarMessageType.ERROR:
        return 'bg-failedsignedin'; 
      default:
        return 'bg-gray-200';
    }
  };

  return (
    <div className={`px-4 pt-2 pb-2 rounded-lg ${getBackgroundColor()}`}>
      <div className="sm:flex items-center">
        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10">
          {getIcon()}
        </div>
        <div className="mt-2 justify-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {message}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default SnackbarMessage;
