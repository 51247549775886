import { toast } from "react-toastify";
import {
  START_LOADER,
  STOP_LOADER,
  RESET_LOADER,
  PAGE_START_LOADER,
  PAGE_STOP_LOADER,
  PAGE_RESET_LOADER,
  SERIES_START_LOADER,
  SERIES_STOP_LOADER,
  SERIES_RESET_LOADER,
  COMIC_BOOK_START_LOADER,
  COMIC_BOOK_STOP_LOADER,
  COMIC_BOOK_RESET_LOADER,
  AUTHOR_START_LOADER,
  AUTHOR_STOP_LOADER,
  AUTHOR_RESET_LOADER,
  GENRE_START_LOADER,
  GENRE_STOP_LOADER,
  GENRE_RESET_LOADER,
  TAG_START_LOADER,
  TAG_STOP_LOADER,
  TAG_RESET_LOADER,
  USER_START_LOADER,
  USER_STOP_LOADER,
  USER_RESET_LOADER,
  ADVERT_START_LOADER,
  ADVERT_STOP_LOADER,
  ADVERT_RESET_LOADER,
  CHAPTER_START_LOADER,
  CHAPTER_STOP_LOADER,
  CHAPTER_RESET_LOADER,
  GUEST_READER_START_LOADER,
  GUEST_READER_STOP_LOADER,
  GUEST_READER_RESET_LOADER,
  
} from "../actions/Type";


const initialState = {
  
  isGuestReaderloading:false,
  isLoading: false,
  isPageLoading: false,
  isChapterLoading: false,
  isSeriesLoading: false,
  isComicBookLoading: false,
  isAuthorLoading: false,
  isGenreLoading: false,
  isTagLoading: false,
  isUserLoading: false,
  isAdvertLoading: false,
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADER:
      return { ...state, isLoading: true };
    case STOP_LOADER:
      return { ...state, isLoading: false };
    case RESET_LOADER:
      return initialState;

    // Page
    case PAGE_START_LOADER:
      return { ...state, isPageLoading: true };
    case PAGE_STOP_LOADER:
      return { ...state, isPageLoading: false };
    case PAGE_RESET_LOADER:
      return { ...initialState, isPageLoading: false };

    // Chapter
    case CHAPTER_START_LOADER:
      return { ...state, isChapterLoading: true };
    case CHAPTER_STOP_LOADER:
      return { ...state, isChapterLoading: false };
    case CHAPTER_RESET_LOADER:
      return { ...initialState, isChapterLoading: false };

    // Series
    case SERIES_START_LOADER:
      return { ...state, isSeriesLoading: true };
    case SERIES_STOP_LOADER:
      return { ...state, isSeriesLoading: false };
    case SERIES_RESET_LOADER:
      return { ...initialState, isSeriesLoading: false };

    // Comic book
    case COMIC_BOOK_START_LOADER:
      return { ...state, isComicBookLoading: true };
    case COMIC_BOOK_STOP_LOADER:
      return { ...state, isComicBookLoading: false };
    case COMIC_BOOK_RESET_LOADER:
      return { ...initialState, isComicBookLoading: false };

    // Author
    case AUTHOR_START_LOADER:
      return { ...state, isAuthorLoading: true };
    case AUTHOR_STOP_LOADER:
      return { ...state, isAuthorLoading: false };
    case AUTHOR_RESET_LOADER:
      return { ...initialState, isAuthorLoading: false };

    // Genre
    case GENRE_START_LOADER:
      return { ...state, isGenreLoading: true };
    case GENRE_STOP_LOADER:
      return { ...state, isGenreLoading: false };
    case GENRE_RESET_LOADER:
      return { ...initialState, isGenreLoading: false };

    // Tag
    case TAG_START_LOADER:
      return { ...state, isTagLoading: true };
    case TAG_STOP_LOADER:
      return { ...state, isTagLoading: false };
    case TAG_RESET_LOADER:
      return { ...initialState, isTagLoading: false };

    // User
    case USER_START_LOADER:
      return { ...state, isUserLoading: true };
    case USER_STOP_LOADER:
      return { ...state, isUserLoading: false };
    case USER_RESET_LOADER:
      return { ...initialState, isUserLoading: false };

    // Guest user
    case GUEST_READER_START_LOADER:
       return { ...state, isGuestReaderloading: true };
    case GUEST_READER_STOP_LOADER:
      return { ...state, isGuestReaderloading: false };
    case GUEST_READER_RESET_LOADER:
      return { ...initialState, isGuestReaderloading: false };

    // Advert
    case ADVERT_START_LOADER:
      return { ...state, isAdvertLoading: true };
    case ADVERT_STOP_LOADER:
      return { ...state, isAdvertLoading: false };
    case ADVERT_RESET_LOADER:
      return { ...initialState, isAdvertLoading: false };

    default:
      return state;
  }
};

export default AppReducer;
